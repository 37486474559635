import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-who',
  templateUrl: './who.component.html',
  styleUrls: ['./who.component.scss']
})
export class WhoComponent implements OnInit {

  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Cyber Right Consulting – Education Consulting for the IT World");

    if(navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      const headerBgs = document.querySelectorAll("#header-slideshow li span");
      headerBgs.forEach((e) => {
        e.classList.add("ios-header-slideshow-bg-up-closed");
        const iosMenuMargin = function() {
          if((window.innerWidth <= 768 && window.scrollY > 60) || (window.innerWidth <= 1200 && window.scrollY > 64)) {
            if(e.classList.contains("ios-header-slideshow-bg-up-closed")) {
              e.classList.remove("ios-header-slideshow-bg-up-closed");
              e.classList.add("ios-header-slideshow-bg-down-closed");
            }
            if(e.classList.contains("ios-header-slideshow-bg-up-opened")) {
              e.classList.remove("ios-header-slideshow-bg-up-opened");
              e.classList.add("ios-header-slideshow-bg-down-opened");
            }
          } else {
            if(e.classList.contains("ios-header-slideshow-bg-down-closed")) {
              e.classList.remove("ios-header-slideshow-bg-down-closed");
              e.classList.add("ios-header-slideshow-bg-up-closed");
            }
            if(e.classList.contains("ios-header-slideshow-bg-down-opened")) {
              e.classList.remove("ios-header-slideshow-bg-down-opened");
              e.classList.add("ios-header-slideshow-bg-up-opened");
            }
          }
        }

        window.addEventListener("scroll", (iosMenuMargin));
      });
    }

    const scroll = window.requestAnimationFrame || function(callback){window.setTimeout(callback, 50)};
    const elementsToShow = document.querySelectorAll('.show-on-scroll');
    const isElementInViewport = function(element) {
      const rect = element.getBoundingClientRect();
      return (
        (rect.top <= 0
          && rect.bottom >= 0)
        ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      );
    }
    const loop = function() {
      elementsToShow.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add('is-visible');
        } else {
          element.classList.remove('is-visible');
        }
      });
      scroll(loop);
    }
    loop();
  }

  smoothScrollWhoweare() {
    const contentWhoarewe = document.getElementById("content-who");
    const elementPosition = contentWhoarewe.getBoundingClientRect().top;
    let headerOffset = 100;
    if(window.innerHeight > 700) {headerOffset = 80;}
    if(window.innerWidth <= 1200) {headerOffset = 0;}
    const offsetPosition = elementPosition - headerOffset;    
    window.scrollTo({top: offsetPosition, behavior: "smooth"});
  }

  smoothScrollContact() {
    const contentContact = document.getElementById("contact");
    contentContact.scrollIntoView({behavior: "smooth"});
  }

}
