import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-think',
  templateUrl: './think.component.html',
  styleUrls: ['./think.component.scss']
})
export class ThinkComponent implements OnInit {

  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("What Our Customers Think? – Cyber Right Consulting");

    const testimonials = document.querySelectorAll('.testimonial') as NodeListOf<HTMLElement>;
    let testimonialMaxHeight = 0;
    testimonials.forEach((e) => {
      testimonialMaxHeight = (e.clientHeight > testimonialMaxHeight) ? e.clientHeight : testimonialMaxHeight;
    })
    testimonials.forEach((e) => {
      if(window.innerWidth > 1200) {
        e.style.height = `${testimonialMaxHeight + 15}px`;
      }
    })

    const scroll = window.requestAnimationFrame || function(callback){window.setTimeout(callback, 50)};
    const elementsToShow = document.querySelectorAll('.show-on-scroll');
    const isElementInViewport = function(element) {
      const rect = element.getBoundingClientRect();
      return (
        (rect.top <= 0
          && rect.bottom >= 0)
        ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      );
    }
    const loop = function() {
      elementsToShow.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add('is-visible');
        } else {
          element.classList.remove('is-visible');
        }
      });
      scroll(loop);
    }
    loop();
  }

}
