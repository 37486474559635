import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-nf',
  templateUrl: './nf.component.html',
  styleUrls: ['./nf.component.scss']
})
export class NfComponent implements OnInit {

  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("404 Page Not Found – Cyber Right Consulting");
  }

  homepage() {
    const logo = document.querySelector(".logo a") as HTMLElement;
    logo.click();
  }

}
