import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NfComponent } from './nf/nf.component';
import { WhoComponent } from './who/who.component';
import { DeliverComponent } from './deliver/deliver.component';
import { AwareComponent } from './aware/aware.component';
import { ThinkComponent } from './think/think.component';
import { NavbarComponent } from './navbar/navbar.component';
import { PreloaderComponent } from './preloader/preloader.component';
import { ContactComponent } from './contact/contact.component';

@NgModule({
  declarations: [
    AppComponent,
    NfComponent,
    WhoComponent,
    DeliverComponent,
    AwareComponent,
    ThinkComponent,
    NavbarComponent,
    PreloaderComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
