import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-aware',
  templateUrl: './aware.component.html',
  styleUrls: ['./aware.component.scss']
})
export class AwareComponent implements OnInit {

  private contactNFooter;
  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Why Security Awareness Training? – Cyber Right Consulting");

    this.contactNFooter = document.querySelectorAll("#contact, #contact-padding1, #contact-padding2, footer") as NodeListOf<HTMLElement>;
    this.contactNFooter.forEach((e) => {e.style.display = "none"});

    const scroll = window.requestAnimationFrame || function(callback){window.setTimeout(callback, 50)};
    const elementsToShow = document.querySelectorAll('.show-on-scroll');
    const isElementInViewport = function(element) {
      const rect = element.getBoundingClientRect();
      return (
        (rect.top <= 0
          && rect.bottom >= 0)
        ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      );
    }
    const loop = function() {
      elementsToShow.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add('is-visible');
        } else {
          element.classList.remove('is-visible');
        }
      });
      scroll(loop);
    }
    loop();
  }

  findOutMore() {
    const btnFind = document.getElementById("btn-find");

    let listenerAdded = false;

    const header = document.getElementById("header-aware");
    const headerInfo = document.getElementById("header-info-aware");
    header.style.transition = "height 0.8s ease";
    headerInfo.style.transition = "margin-top 1s ease";

    if(window.innerWidth <= 1200 && window.innerWidth > 768) {
      header.style.height = "500px";
      header.style.marginTop = "0";
      headerInfo.style.marginTop = "120px";
    }
    else if(window.innerWidth <= 768) {
      header.style.height = "300px";
      header.style.marginTop = "0";
      headerInfo.style.marginTop = "80px";
    }
    else {
      if(window.innerHeight <= 700) {
        header.style.height = "500px";
        header.style.marginTop = "80px";
        headerInfo.style.marginTop = "120px";
      }
      else {
        header.style.height = "500px";
        header.style.marginTop = "100px";
        headerInfo.style.marginTop = "120px";
      }
    }

    const contents = document.getElementsByClassName("content") as HTMLCollectionOf<HTMLElement>;
    if(btnFind.style.opacity != "0") {
      const headerBtns = document.getElementById("header-buttons");
      btnFind.style.transform = "translateX(200px)";
      btnFind.style.opacity = "0";
      for(let i = 0; i < contents.length; i++) {
        contents[i].style.display = "flex";
      }
      if(window.innerWidth <= 1200 && window.innerWidth > 768) {
        window.scrollBy({top: 100, behavior: "smooth"});
      } else if(window.innerWidth <= 768) {
        window.scrollBy({top: 80, behavior: "smooth"});
      } else {
        if(window.innerHeight <= 700) {
          window.scrollBy({top: 250, behavior: "smooth"});
        } else {
          window.scrollBy({top: 150, behavior: "smooth"});
        }
      }
      this.contactNFooter.forEach((e) => {
        if(e.id == "contact")
          e.style.display = "flex";
        else
          e.style.display = "block";
      });
      setTimeout(() => {headerBtns.style.display = "none";}, 500);
    }

    if(!listenerAdded)
      window.addEventListener("resize", this.findOutMore);
  }
}
