import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(private location: Location) { }
  private showMenu = false;

  ngOnInit() {    
    const path = this.location.path();
    if(path == "" || path == "/") {
      this.navStyle("who");
    }
    else if(path == "/delivered-by-cyberright") {
      this.navStyle("del");
    }
    else if(path == "/why-security-awareness-training") {
      this.navStyle("why");
    }
    else if(path == "/what-our-customers-think"){
      this.navStyle("thi");
    }

    const menu = document.getElementById("menu");
    const fixedMobileNav = function() {
      if((window.innerWidth <= 768 && window.scrollY > 60) || (window.innerWidth <= 1200 && window.scrollY > 64)) {
        menu.style.position = "fixed";
      } else {
        menu.style.position = "static";
      }
    }

    window.addEventListener("scroll", (fixedMobileNav));
  }

  clickMenu() {
    const menuBtn = document.querySelector(".menu-btn");
    const mobileNav = document.querySelector<HTMLUListElement>("#mobile-nav ul");

    if (!this.showMenu) {
      menuBtn.classList.add("close");
      mobileNav.style.display = "block";
      if(navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        const headerBgs = document.querySelectorAll("#header-slideshow li span");
        headerBgs.forEach((e) => {
          if((window.innerWidth <= 768 && window.scrollY > 60) || (window.innerWidth <= 1200 && window.scrollY > 64)) {
            e.classList.remove("ios-header-slideshow-bg-down-closed");
            e.classList.add("ios-header-slideshow-bg-down-opened");
          } else {
            e.classList.remove("ios-header-slideshow-bg-up-closed");
            e.classList.add("ios-header-slideshow-bg-up-opened");
          }
        });
      }
      this.showMenu = true;
    }
    else {
      menuBtn.classList.remove("close");
      mobileNav.style.display = "none";
      if(navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
        const headerBgs = document.querySelectorAll("#header-slideshow li span");
        headerBgs.forEach((e) => {
          if((window.innerWidth <= 768 && window.scrollY > 60) || (window.innerWidth <= 1200 && window.scrollY > 64)) {
            e.classList.remove("ios-header-slideshow-bg-down-opened");
            e.classList.add("ios-header-slideshow-bg-down-closed");
          } else {
            e.classList.remove("ios-header-slideshow-bg-up-opened");
            e.classList.add("ios-header-slideshow-bg-up-closed");
          }
        });
      }
      this.showMenu = false;
    }
  }

  navStyle(navChar: string) {
    const screenSizes = ["d", "m"]; // d for desktop, m for mobile

    for(let size of screenSizes) {
      const navs = document.getElementsByClassName(`nav-${size}`) as HTMLCollectionOf<HTMLElement>;
      const selectedNav = document.getElementById(`nav-${size}-${navChar}`);

      for(let i = 0; i < navs.length; i++) {
        if(size == "d") {
          navs[i].style.color = "#202020";
          navs[i].parentElement.style.borderBottom = "none";
          navs[i].onmouseover = (() => {
            navs[i].style.color = "#073A8C";
            navs[i].parentElement.style.borderBottom = "2px solid #073A8C";
          })
          navs[i].onmouseout = (() => {
            navs[i].style.color = "#202020";
            navs[i].parentElement.style.borderBottom = "none";
          })
        }
        if(size == "m") {
          navs[i].style.color = "#F0F0F0";
          navs[i].onmouseover = (() => {
            navs[i].style.color = "#69A3FF";
          })
          navs[i].onmouseout = (() => {
            navs[i].style.color = "#F0F0F0";
          })
        }
      }

      if(size == "d") {
        selectedNav.style.color = "#073A8C";
        selectedNav.parentElement.style.borderBottom = "2px solid #073A8C";
        selectedNav.onmouseout = (() => {
          selectedNav.style.color = "#073A8C";
          selectedNav.parentElement.style.borderBottom = "2px solid #073A8C";
        })
      }
      if(size == "m") {
        selectedNav.style.color = "#69A3FF";
        selectedNav.onmouseout = (() => {
          selectedNav.style.color = "#69A3FF";
        })
      }
    }
  }

  clickNav(navChar: string) {
    this.navStyle(navChar);
    this.clickMenu();
  }

}
