import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    const preloader = document.getElementById("preloader");
    setTimeout(() => {
      preloader.style.opacity = "0";
    }, 200)
    let stateCheck = setInterval(() => {
      if (document.readyState === 'complete') {
        clearInterval(stateCheck);
        setTimeout(() => {
          preloader.style.display = "none";
        }, 1500)
      }
    }, 100);

    const toTop = document.querySelector(".to-top");
    const trackScroll = function() {
      if (window.pageYOffset >= 500) {
        toTop.classList.add('to-top-show');
      }
      if (window.pageYOffset < 500) {
        toTop.classList.remove('to-top-show');
      }
    }
    window.addEventListener('scroll', trackScroll);
  }

  smoothScrollTop() {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }

}
