import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-deliver',
  templateUrl: './deliver.component.html',
  styleUrls: ['./deliver.component.scss']
})
export class DeliverComponent implements OnInit {

  constructor(private title: Title) { }

  ngOnInit() {
    this.title.setTitle("Delivered by Cyber Right – Cyber Right Consulting");

    const scroll = window.requestAnimationFrame || function(callback){window.setTimeout(callback, 50)};
    const elementsToShow = document.querySelectorAll('.show-on-scroll');
    const isElementInViewport = function(element) {
      const rect = element.getBoundingClientRect();
      return (
        (rect.top <= 0
          && rect.bottom >= 0)
        ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
          rect.top <= (window.innerHeight || document.documentElement.clientHeight))
        ||
        (rect.top >= 0 &&
          rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
      );
    }
    const loop = function() {
      elementsToShow.forEach((element) => {
        if (isElementInViewport(element)) {
          element.classList.add('is-visible');
        } else {
          element.classList.remove('is-visible');
        }
      });
      scroll(loop);
    }
    loop();
  }

  smoothScrollContact() {
    const contentContact = document.getElementById("contact");
    contentContact.scrollIntoView({behavior: "smooth"});
  }

}
