import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NfComponent } from './nf/nf.component';
import { WhoComponent } from './who/who.component';
import { DeliverComponent } from './deliver/deliver.component';
import { AwareComponent } from './aware/aware.component';
import { ThinkComponent } from './think/think.component';


const routes: Routes = [
  { path: '', component: WhoComponent },
  { path: 'delivered-by-cyberright', component: DeliverComponent },
  { path: 'why-security-awareness-training', component: AwareComponent },
  { path: 'what-our-customers-think', component: ThinkComponent },
  { path: '**', component: NfComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
